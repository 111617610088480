/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Layout from '../../components/layout'
import ContentWrapper from '../../components/ContentWrapper'
import DetailContainer from '../../components/DetailContainer'
import BlogContent from '../../components/BlogContent'
import { A, P, BlogHeading, BlogHeadingLink } from '../../components/Typography'
import { SPACING, BREAKPOINT, COLOR } from '../../constants'
import BlogHero from '../../components/BlogHero'
import Pagination from '../../components/Pagination'

const StyledImage = styled(Img)`
  object-fit: contain;
`

const Line = styled.div`
  border-bottom: 1px solid ${COLOR.GRAY.LIGHT};
  width: 100%;
  margin-top: ${SPACING.XL};
`

const CoverImageContainer = styled.div`
  margin-top: ${SPACING.L};
  margin-bottom: ${SPACING.L};
  min-width: 400px;

  @media (min-width: ${BREAKPOINT.M}) {
    min-width: 712px;
  }

  @media (min-width: ${BREAKPOINT.L}) {
    min-width: 666px;
  }
`

const blogPostTemplate = ({ data: { blogPosts }, pageContext }) => {
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 ? '/blog' : `/blog/${currentPage - 1}`
  const nextPage = `/blog/${currentPage + 1}`

  return (
    <Layout title="Design patterns for mental health blog">
      <ContentWrapper>
        <BlogHero>
          <BlogHeading>Design patterns for mental health blog</BlogHeading>
          {/* <P>Description of blog will go here</P> */}
        </BlogHero>

        <DetailContainer>
          <BlogContent>
            {blogPosts.nodes.map((blogPost, blogIndex) => (
              <div key={blogPost.id}>
                <BlogHeadingLink href={`/blog/${blogPost.slug}`}>
                  {blogPost.title}
                </BlogHeadingLink>
                <P>
                  by {blogPost.author.authorName}, {blogPost.publishDate}
                </P>
                <CoverImageContainer>
                  <StyledImage
                    fluid={blogPost.coverImage.fluid}
                    alt={blogPost.coverImage.description}
                  />
                </CoverImageContainer>
                <P>{blogPost.postDescription.internal.content}</P>
                <A href={`/blog/${blogPost.slug}`}>Read more</A>
                {blogIndex + 1 < blogPosts.nodes.length && <Line />}
              </div>
            ))}
            <Pagination
              isFirst={isFirst}
              isLast={isLast}
              prevPage={prevPage}
              nextPage={nextPage}
            />
          </BlogContent>
        </DetailContainer>
      </ContentWrapper>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int! = 0, $limit: Int! = 10) {
    blogPosts: allContentfulBlogPost(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: publishDate }
    ) {
      nodes {
        id
        slug
        publishDate(formatString: "Do MMMM YYYY")
        author {
          authorName
        }
        title
        postDescription {
          internal {
            content
          }
        }
        coverImage {
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid
          }
          description
        }
      }
    }
  }
`

export default blogPostTemplate
