import styled from 'styled-components'
import { BREAKPOINT, SPACING, WIDTH } from '../constants'

const BlogContent = styled.div`
  max-width: ${WIDTH.LINE_LIMIT};

  @media (min-width: ${BREAKPOINT.L}) {
    margin-right: ${SPACING.XXXL};
    margin-left: 0;
  }
`
export default BlogContent
