import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { A } from './Typography'
import { SPACING } from '../constants'

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${SPACING.XXXL};
`

const PreviousPageContainer = styled.div`
  width: 50%;
`

const NextPageContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row-reverse;
`

const Pagination = ({ isFirst, isLast, prevPage, nextPage }) => (
  <PaginationContainer>
    <PreviousPageContainer>
      {!isFirst && <A href={prevPage}>Previous page</A>}
    </PreviousPageContainer>

    <NextPageContainer>
      {!isLast && <A href={nextPage}>Next page</A>}
    </NextPageContainer>
  </PaginationContainer>
)

Pagination.propTypes = {
  isFirst: propTypes.bool.isRequired,
  isLast: propTypes.bool.isRequired,
  prevPage: propTypes.string.isRequired,
  nextPage: propTypes.string.isRequired,
}
export default Pagination
